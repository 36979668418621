"use client";
import React, { useEffect, useRef, useState } from "react";
import bell from "../../assets/icons/bell.svg";
import refresh from "../../assets/icons/refresh.svg";

import cross from "../../assets/icons/cross.svg";

// import { Pagination } from "./Pagination";
import "./Notification.scss";
import { getList } from "../../services/userServices";
import { ENDPOINT, ERROR_ACTIONS, USER_TYPE } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import { PAGE_SIZE } from "../../config/config";
import { Box, Grid, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Encrypt } from "../../Crypto-Helper";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setExternalUserNotificationData } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import { setCustomerReservationData } from "../../redux/slices/masterDataSlice";


const Notification = () => {
  const { logout } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [isNotification, setIsNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isRefresh, setRefresh] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const [isDialogOpen, setDialogOpen] = useState(false); // For confirmation dialog
  const [selectedNotificationId, setSelectedNotificationId] = useState(null); // Selected notification ID
  const [totalNotificationCount, setNotificationCount] = useState(0)



  const handleClickNotification = () => {
    getNotificationsList();
    setIsNotification(!isNotification);
  };

  const handleCloseNotification = () => {
    setIsNotification(false);
  };

  const getNotificationsList = async () => {
    const payload = new FormData();
    const previousCount = currentPage * PAGE_SIZE - PAGE_SIZE;
    payload.append("previousCount", previousCount);

    const resp = await getList(
      "POST",
      ENDPOINT.NOTIFICATION_LIST,
      payload
    );
    if (!resp?.error) {
      setNotifications(resp?.data?.data);
      setNotificationCount(resp?.data?.totalRecordsCount)
      setTotalPageCount(
        (resp?.data?.totalRecordsCount || 0) > PAGE_SIZE ? Math.ceil((resp?.data?.totalRecordsCount || 0) / PAGE_SIZE) : 0
      );
      // setTotalCountToday(resp?.data);
      // dispatch(setTodaysList(resp?.data));
      // dispatch(setTodaysCount(resp?.data?.totalRecordsCount || 0));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  useEffect(() => {
    getNotificationsList()
  }, [isRefresh, currentPage])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const day = date.getDate();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' :
      day % 10 === 2 && day !== 12 ? 'nd' :
        day % 10 === 3 && day !== 13 ? 'rd' : 'th';

    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    return `${day} ${month} ${year}, ${formattedHours} : ${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const refreshClick = () => {
    setRefresh((prev) => prev + 1);
  }

  const reserve = (notification) => {
    dispatch(setCustomerReservationData(null));
    dispatch(setExternalUserNotificationData(notification));
    navigate(`/reservation/${Encrypt(notification?.customerId)}`);
    setIsNotification(false);
  }

  const decline = async (id) => {

    setSelectedNotificationId(id); // Set the selected notification ID
    setDialogOpen(true); // Open the dialog
    setIsNotification(true);

  }

  const handleDeclineConfirm = async () => {
    const payload = new FormData();
    payload.append("id", selectedNotificationId);
    const response = await getList("POST", ENDPOINT.CANCEL_RESERVATION, payload);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message)

      const updatedTotalNotificationCount = totalNotificationCount - 1;

      // Recalculate the total pages
      const updatedTotalPageCount = Math.ceil(updatedTotalNotificationCount / PAGE_SIZE);

      // Adjust the current page if necessary
      if (currentPage > updatedTotalPageCount) {
        setCurrentPage(updatedTotalPageCount); // Move to the last valid page
      }

      setDialogOpen(false); // Close the dialog
      setRefresh((prev) => prev + 1); // Refresh notifications
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedNotificationId(null);
  };


  return (
    <div className="notificationWrap" ref={notificationRef}>
      <div className="notificationIcon" onClick={(event) => {
        event.stopPropagation(); // Prevent the dropdown from closing
        handleClickNotification();
      }}>
        <img src={bell} alt="" />
        <span></span>
      </div>

      <div
        className={`notification-dropdown dd ${isNotification ? "dropdown-transition" : ""
          }`}
      >
        <div className="arrow-up"></div>
        <div className="shadow">
          <div className="notify">
            <div className="text">
              Notifications <span>{totalNotificationCount}</span>
            </div>
            <div className="refreshAndClose">
              <div className="refreshBtn" title="Refresh" onClick={refreshClick}>
                <img src={refresh} alt="" />
              </div>
              <div className="closeIcon" onClick={handleCloseNotification}>
                <img src={cross} alt="" />
              </div>
            </div>
          </div>
          <div className="items">
            <ul className="ulItems">
              {notifications?.length > 0 ? (
                notifications.map((notification, index) => (
                  <li key={index}>
                    <div className="namePhoto">
                      {/* <div className="photo"></div> */}
                      <div className="name">
                        <b>Booking - {notification?.name} for </b> <span>{notification?.restaurantName}</span>
                      </div>
                    </div>
                    <div className="timeAgo">
                      <span>{formatDateTime(notification?.reservationTime).split(',')[0]}</span>
                      <span className="dots"></span>
                      <span>{formatDateTime(notification?.reservationTime).split(',')[1]}</span>
                      <span className="dots"></span>
                      <span>{notification?.peopleCount} Pax</span>
                    </div>
                    <div className="buttonsGroup">
                      <button className="btnDecline" onClick={(event) => {
                        event.stopPropagation(); // Prevent the dropdown from closing
                        decline(notification?.id);
                      }}>Cancel</button>
                      <button className="btnAccept" onClick={() => reserve(notification)}>Reserve</button>
                    </div>
                  </li>
                ))
              ) : (
                <li>No notifications available.</li>
              )}

            </ul>
          </div>
          {/* <div>
            <Pagination />
          </div> */}
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 14,
              }}
            >
              {totalPageCount && notifications?.length > 0 ? (
                <Pagination
                  count={totalPageCount}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  page={currentPage}
                  onChange={(event, value) => {
                    setCurrentPage(value);
                    // window.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                  }

                />
              ) : null}
            </Box>
          </Grid>
        </div>
      </div>


      <Dialog onClose={handleDialogClose} open={isDialogOpen} PaperProps={{
        onMouseDown: (event) => {
          event.stopPropagation(); // Prevent the event from propagating to the parent
        },
      }}>
        <Box
          sx={{
            position: "sticky",
            background: "white",
            top: 0,
            zIndex: 2,
            p: 2,
          }}
        >

          <DialogTitle>Confirm Cancel</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel this reservation?
            </DialogContentText>
          </DialogContent>



          <DialogActions>
            <Button variant="text" onClick={(event) => {
              event.stopPropagation(); // Prevent the dropdown from closing
              handleDialogClose();
            }}>
              No
            </Button>
            <Button variant="contained" onClick={handleDeclineConfirm}>
              Yes
            </Button>
          </DialogActions>


        </Box>
      </Dialog>

    </div>
  );
};

export default Notification;
