export function convertDateFormat(inputDate, format) {
  // Split the input date string into day, month, and year components
  let components = inputDate.split("/");
  let day = components[0];
  let month = components[1];
  let year = components[2];

  // Construct the new format: yyyy-dd-mm
  //   let newFormat = year + "-" + month + "-" + day;
  let newFormat = year + "-" + month + "-" + day;
  if (format === "dd-mm-yyyy") {
    newFormat = day + "-" + month + "-" + year;
  }
  return newFormat;
}

export function formatISODateString(_date) {
  // Create a Date object from the ISO date string
  let date = new Date(_date);

  // Extract the year, month, and day components
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it's 0-based
  let day = date.getDate().toString().padStart(2, "0");

  // Create the yyyy-mm-dd formatted date string
  let formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
export function formatDatetime(inputDatetime, dateOnly = false) {
  // Parse the input datetime string into a Date object
  const date = new Date(inputDatetime);

  // Extract the date components
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const year = date.getFullYear();

  // Extract the time components
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  if (hours > 12) {
    hours -= 12;
  }

  // Format the components with leading zeros if needed
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");
  const formattedYear = String(year);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Create the formatted datetime string
  if (dateOnly) {
    const formattedDatetime = `${formattedDay}/${formattedMonth}/${formattedYear}`;
    return formattedDatetime;
  } else {
    const formattedDatetime = `${formattedDay}/${formattedMonth}/${formattedYear} ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDatetime;
  }
}

export function convertTo12HourFormat(time24){
  if (!time24) return null;
  const [hours, minutes] = time24.split(':'); // Split into components
  const hour = parseInt(hours, 10); // Convert hours to an integer
  const period = hour >= 12 ? 'PM' : 'AM'; // Determine AM or PM
  const adjustedHour = hour % 12 || 12; // Adjust for 12-hour format
  return {
    hour: adjustedHour.toString(), // Hour as a string
    minutes,
    period
  };
};

export function convertToAMPM(timeString, getArray = false) { 
  var  period = "";
  const [hours, minutes] = timeString?.split(":")?.map(Number);
  
  let ampm = timeString?.split(" ")[1];
  if(ampm === undefined){
    period = hours >= 12 ? 'PM' : 'AM';
  }
  
  let formattedHours = hours % 12 || 12;
  formattedHours = String(formattedHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  if (getArray) {
    return [formattedHours, formattedMinutes, ampm ? ampm : period];
  }
  return `${formattedHours}:${formattedMinutes} ${ampm ? ampm : period}`;
}

export function convertSimpleDateFormat(dateString) {
  const [year, month, day] = dateString.split("-");
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

export const maskNumber = (number) => {
  const maskedNumber = "********" + number.slice(-2);
  return maskedNumber;
};

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function generateBatches(totalCount, batchSize = 500) {
  const batches = [];
  let start = 0;
  let end = batchSize - 1;
  if (totalCount < 500) {
    return batches;
  }
  while (start < totalCount) {
    if (end >= totalCount - 1) {
      batches.push(`${start}-${totalCount - 1}`);
    } else {
      batches.push(`${start}-${end}`);
    }
    start = end + 1;
    end = start + batchSize - 1;
  }

  return batches;
}

export function getShortMonthDate(_date) {
  const date = new Date(_date); // Create a Date object with the desired date

  const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad with '0' if needed
  const month = date.toLocaleString("default", { month: "short" }); // Get the abbreviated month name
  const year = date.getFullYear(); // Get the year

  return `${day} ${month.toUpperCase()} ${year}`; // Output: "03 Oct 2023"
}

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours.toString().length === 1 ? "0" + hours : hours;
  minutes = minutes <= 30 ? "0" + 0 : 30;
  return { hours, minutes, ampm };
}

export function isCurrentDatetimeGreaterThanGivenDatetime(givenDatetime) {
  // Create a Date object for the given datetime
  const givenDate = new Date(givenDatetime);

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate - givenDate;

  // Calculate the number of milliseconds in six hours
  const sixHoursInMillis = 6 * 60 * 60 * 1000;

  // Check if the time difference is greater than or equal to six hours
  return timeDifference >= sixHoursInMillis;
}

export function addMinutesToTime(timeString, minutesToAdd) {
  const timeRegex = /(\d{1,2}):(\d{2}):(\d{2}) (\w{2})/;
  const match = timeString.match(timeRegex);

  if (!match) {
    return "Invalid time format";
  }

  let hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  const seconds = parseInt(match[3], 10);
  const period = match[4].toLowerCase();

  if (period === "pm" && hours !== 12) {
    hours += 12;
  } else if (period === "am" && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  date.setMinutes(date.getMinutes() + minutesToAdd);

  let updatedHours = date.getHours();
  const updatedMinutes = date.getMinutes();
  const updatedSeconds = date.getSeconds();
  const updatedPeriod = updatedHours < 12 ? "AM" : "PM";
  updatedHours %= 12;
  updatedHours = updatedHours || 12;

  return `${updatedHours.toString().padStart(2, "0")}:${updatedMinutes
    .toString()
    .padStart(2, "0")}:${updatedSeconds
    .toString()
    .padStart(2, "0")} ${updatedPeriod}`;
}

export function formatReservationDate(inputDate) {
  // Parse the input date string in "yyyy-mm-dd" format
  const parts = inputDate.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Create a new Date object
  const date = new Date(year, month - 1, day); // Note: months are 0-indexed in JavaScript

  // Format the date as "dd-month-yyyy"
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function compareTime(targetTime) {
  // Get the current time
  const currentTime = new Date();

  // Parse the target time
  const targetTimeParts = targetTime.match(/(\d+):(\d+):(\d+) (AM|PM)/);
  const targetHour =
    parseInt(targetTimeParts[1], 10) + (targetTimeParts[4] === "PM" ? 12 : 0);
  const targetMinute = parseInt(targetTimeParts[2], 10);
  const targetSecond = parseInt(targetTimeParts[3], 10);

  // Set the target time
  const targetDate = new Date();
  targetDate.setHours(targetHour, targetMinute, targetSecond, 0);

  // Compare the current time with the target time
  if (currentTime.getTime() > targetDate.getTime()) {
    return false;
  } else if (currentTime.getTime() < targetDate.getTime()) {
    return true;
  } else {
    return false;
  }
}

export function convertNumbers(str) {
  // Split the string by comma
  let parts = str.split(",");

  // Iterate over each item
  for (let i = 0; i < parts.length; i++) {
    let num = parseInt(parts[i], 10); // Parse the item as an integer
    // Check if it's a single-digit number
    if (num >= 0 && num < 10) {
      parts[i] = "0" + num; // Append zero before it
    }
  }

  // Join the modified items with comma
  let newStr = parts.join(", ");

  return newStr;
}

export function formatTableNumber(tableNumber) {
  // Check if the table number is numeric
  if (!isNaN(tableNumber)) {
    // Convert numeric table number to padded format
    return tableNumber?.padStart(2, "0");
  } else {
    // If alphanumeric, return as it is
    return tableNumber;
  }
}
