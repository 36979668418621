import React, { useCallback, useEffect, useState } from "react";
import {
  FormLabel,
  TextField,
  Box,
  Avatar,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Autocomplete,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Styles from "./styles/customer-search.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { GET, POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { searchCustomer } from "../../redux/actions/customerActions";
import {
  resetCustomerData,
  setCountryCode,
  setCustomerData,
  setError,
  setLoading,
  setMobileNumber,
} from "../../redux/slices/customerSlice";
import CustomerSearchResult from "./CustomerSearchResult";
import { IMAGE_BASE_URL } from "../../config/config";
import AccessDenied from "../access-denied/AccessDenied";
import { Encrypt } from "../../Crypto-Helper";
import Ticket from "../../components/ticket/Ticket";
import { toast } from "react-toastify";
import {
  setCountryCodes,
  setCustomerReservationData,
  setRestaurantsList,
} from "../../redux/slices/masterDataSlice";
import { addMinutesToTime, formatAMPM, formatISODateString } from "../../utils";
import { setExternalUserNotificationData } from "../../redux/slices/userSlice";


const CustomerSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  

  const { loading, data, mobileNumber, countryCode } = useSelector(
    (state) => state.customer
  );
  const { countryCodes } = useSelector((state) => state.masterData);
  const [open, handleOpen] = useState(false);
  const [filteredCountryCodes, setFilteredCountryCodes] =
    useState(countryCodes);
  const { logout, role } = useAuth();
  const [mobile, setMobile] = React.useState(mobileNumber);
  const [imagePresent, setImagePresent] = React.useState(true);
  const [showSearchResult, setShowSearchResult] = React.useState(false);
  const [visitCount, setVisitCount] = useState(`${data?.visitCount}` || "");
  const [country, setCountry] = useState("91");
  const [mounted, setMounted] = useState(false);
  const [reservationLoading, setReservationLoading] = useState(false);
  const [time, setTime] = useState({
    hh: formatAMPM(new Date())?.hours,
    mm: formatAMPM(new Date())?.minutes,
    meridiem: formatAMPM(new Date())?.ampm,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    !open && setFilteredCountryCodes(countryCodes);
  }, [open]);

  useEffect(() => {
    setMobile("");
    dispatch(setMobileNumber(""));
    getCountryCodes();
    dispatch(resetCustomerData());
    setMounted(true);
    getAllReservations();
  }, []);

  useEffect(() => {
    mounted && mobile && checkMobileNumberExist(mobile);
  }, [country]);

  const handleClose = () => {
    handleOpen(false);
  };

  const handleOpenDialog = (value) => {
    handleOpen(true);
  };

  const getAllReservations = async () => {
    try {
      const payload = new FormData();
      payload.append(
        "reservationDate",
        formatISODateString(new Date().toISOString())
      );
      payload.append("fromTime", `${time.hh}:${time.mm}:00 ${time.meridiem}`);
      payload.append(
        "toTime",
        addMinutesToTime(`${time.hh}:${time.mm}:00 ${time.meridiem}`, 120)
      );
      const resp = await POST_MULTIPART(
        ENDPOINT.GET_ALL_RESERVATION_BY_DATE_TIME,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleMobileNumberChange = (event) => {
    if (
      (country === "91" && event.target.value.length > 10) ||
      isNaN(event.target.value)
    ) {
      return;
    } else if (event.target.value.length > 12 || isNaN(event.target.value)) {
      return;
    }

    setMobile(event.target.value);
    if (parseInt(country) === 91 && event.target.value.length === 10) {
      dispatch(setMobileNumber(event.target.value));
      dispatch(setCountryCode(country));
      checkMobileNumberExist(event.target.value);
    }
  };

  const handleSubmit = () => {
    dispatch(setMobileNumber(mobile));
    dispatch(setCountryCode(country));
    checkMobileNumberExist(mobile);
  };

  const getCountryCodes = async () => {
    try {
      const resp = await GET(ENDPOINT.GET_COUNTRIES);
      if (resp?.response?.data?.status === 200) {
        dispatch(setCountryCodes(resp?.response?.data?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {}
  };
  const checkMobileNumberExist = async (value) => {
    try {
      dispatch(setLoading(true));
      const response = await POST(ENDPOINT.CHECK_MOBILE_EXIST, {
        countryCode: country,
        contactNo: value,
      });
      dispatch(setLoading(false));
      if (response?.response?.data?.status === 200) {
        dispatch(setCustomerData(response?.response?.data));
        setVisitCount(`${response?.response?.data?.visitCount}`);
        // navigate("/customer-search-result");
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else if (response?.response?.data?.status === 201) {
        dispatch(setError(response.response.data.message));
        if (response?.response?.data?.isDataAvailable === 0) {
          dispatch(setCustomerReservationData(null));
          navigate("/customer-search-result");
        }
      }
    } catch (error) {
      dispatch(setError(error.message));
    }
  };

  const handleImageError = () => {
    setImagePresent(false);
  };

  const handleView = () => {
    navigate(`/customer/edit/${Encrypt(data?.data?.id)}`);
  };

  const handleReserve = async () => {    
    // navigate(`/reservation/${Encrypt(data?.data?.id)}`);
    dispatch(setExternalUserNotificationData(null));
    setReservationLoading(true);
    try {
      await GET(
        `${
          role === USER_TYPE.MANAGER
            ? ENDPOINT.GET_USER_FOR_ADMIN
            : ENDPOINT.GET_USER
        }/${data?.data?.id}`
      ).then((resp) => {
        if (resp?.response?.data?.status === 200) {
          const userData = {
            // ...resp?.response?.data?.data,
            firstName: resp?.response?.data?.data?.name,
            lastName: resp?.response?.data?.data?.lastName,
            countryCode: resp?.response?.data?.data?.countryCode,
            gender: resp?.response?.data?.data?.gender,
          };
          
            dispatch(setCustomerReservationData(userData));
           
          navigate(`/reservation/${Encrypt(data?.data?.id)}`);
        } else if (
          resp?.response?.data?.status === 401 &&
          resp?.response?.data?.message === "User Token Expired"
        ) {
          logout();
        } else {
          toast.error(resp?.response?.data?.message, TOAST_CONFIG);
        }
      });
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setReservationLoading(false);
    }
  };

  const handleSearch = (query) => {
    const filteredCodes = countryCodes.filter(
      (option) =>
        option?.name.toLowerCase()?.includes(query.toLowerCase()) ||
        option?.phonecode.toString()?.includes(query)
    );
    setFilteredCountryCodes(filteredCodes);
  };

  return (
    <div className={Styles.container}>
      {data?.isDataAvailable && (
        <div className={Styles.customerInfoContainer}>
          <div className={Styles.customerNameWrapper}>
            <h1>
              {data?.data?.firstName} {data?.data?.lastName}
            </h1>
          </div>
          <div className={Styles.alphabetsWrapper}>
            {data?.categoryData?.map((item, index) => (
              <p key={index} className={Styles.alphabet}>
                {item.alphabets}
              </p>
            ))}
            {data?.data?.isDnd ? (
              <p className={`${Styles.alphabet} ${Styles.dnd} `}>X</p>
            ) : null}
          </div>
        </div>
      )}
      <div className={Styles.avatarContainer}>
        <div className={Styles.avatarWrapper}>
          <Avatar
            alt=""
            src={`${IMAGE_BASE_URL}${data?.data?.profileImage}`}
            onError={handleImageError}
            sx={{
              width: "100%", // 406,
              height: 406,
              backgroundColor: imagePresent ? "transparent" : "#eee",
              borderRadius: "15px",
              border: "1px solid #d9d9d9",
            }}
          />
          {data?.isDataAvailable && (
            <div className={Styles.visitCount}>
              <p>{visitCount?.length === 1 ? "0" + visitCount : visitCount}</p>
            </div>
          )}
        </div>

        {data?.isDataAvailable && (
          <div className={Styles.actionButtonContainer}>
            <Button variant="contained" onClick={handleView}>
              View
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReserve}
              disabled={reservationLoading}
            >
              {reservationLoading ? <CircularProgress size={35} /> : "Reserve"}
            </Button>
          </div>
        )}
      </div>

      <FormLabel sx={{ marginBottom: "20px", fontSize: "20px" }}>
        Mobile Number
      </FormLabel>
      <TextField
        autoFocus
        disabled={loading}
        id="mobileNumber"
        name="mobileNumber"
        autoComplete="off"
        type="number"
        placeholder="**********"
        value={mobile}
        onChange={handleMobileNumberChange}
        onKeyDown={(e) => {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault();
          }
        }}
        InputProps={{
          startAdornment: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // width: "25%",
                marginLeft: "15%",
                borderRight: "2px solid #D9D9D9",
                height: "14px",
                color: "#797979",
                fontWeight: "500",
                fontSize: "17.5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  mr: 1,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpen(true);
                }}
              >
                <Typography>+{country}</Typography>
                <ExpandMoreIcon />
              </Box>
            </Box>
          ),
        }}
        inputProps={{
          sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "10px",
            verticleAlign: "middle",
            color: "#797979",
            fontWeight: "500",
            fontSize: "17.5px",
          },
        }}
        sx={{
          width: "313px",
        }}
      />
      {parseInt(country) !== 91 && mobile?.length >= 4 && (
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
      {loading && <CircularProgress color="primary" size={32} sx={{ mt: 2 }} />}
      <Dialog onClose={handleClose} open={open}>
        <Box
          sx={{
            position: "sticky",
            background: "white",
            top: 0,
            zIndex: 2,
            p: 2,
          }}
        >
          <TextField
            type="text"
            placeholder="Search country"
            sx={{
              width: "100%",
            }}
            autoFocus
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            height: "85vh",
            background: "white",
          }}
        >
          {!filteredCountryCodes?.length && (
            <Typography sx={{ textAlign: "center", my: 2 }}>
              No country found!
            </Typography>
          )}
          {filteredCountryCodes?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.phonecode}
              onClick={(e) => {
                setCountry(option.phonecode);
                dispatch(setCountryCode(option.phonecode));
                handleOpen(false);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p>{option.name}</p>
                <p>{`+${option.phonecode}`}</p>
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Dialog>
    </div>
  );
};

export default CustomerSearch;
