import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Style from "./header.module.scss";
import MenuIcon from "../../assets/images/menu-icon.svg";
import FilterIcon from "../../assets/images/icon_filter.svg";
import SettingIcon from "../../assets/images/setting_icon.svg";
import Sidebar from "../sidebar/Sidebar";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCustomerData,
  setMobileNumber,
} from "../../redux/slices/customerSlice";
import Filter from "../filter/Filter";
import {
  setOpenCheckInFilter,
  setOpenChooseServerModal,
  setOpenMainFilter,
  setOpenQRModal,
  setOpenReservationFilter,
} from "../../redux/slices/masterDataSlice";
import { USER_TYPE } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import QRModal from "../qr/QRModal";
import ChooseServerModal from "../choose-server/ChooseServerModal";
import { Encrypt } from "../../Crypto-Helper";
import Logo from "../../assets/images/Datalekt_Dots.svg";
import Notification from "./Notification";

const DashboardButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.dark,
  //   "&:hover": {
  //     backgroundColor: theme.palette.secondary.light,
  //   },
  textTransform: "none",
  borderRadius: "30px",
  height: "41px",
  width: "121px",
}));

const Header = () => {
  const {
    activeDashboardTab,
    activeExportTab,
    openMainFilter,
    openReservationFilter,
    openCheckInFilter,
    openQRModal,
    openChooseServerModal,
    templateId,
  } = useSelector((state) => state.masterData);
  const { role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleDrawer = (param) => {
    setSidebarOpen(param);
  };

  const handleCloseQRModal = () => {
    dispatch(setOpenQRModal(false));
  };
  const handleCloseChooseServerModal = () => {
    dispatch(setOpenChooseServerModal(false));
  };
  const handleSearch = () => {
    dispatch(resetCustomerData());
    dispatch(setMobileNumber(""));
    navigate("/customer-search");
  };
  const getPageName = () => {
    if (location.pathname.includes("edit-template")) {
      return "Edit Template";
    }
    if (location.pathname.includes("settings")) {
      return "Template Settings";
    }
    if (location.pathname.includes("history")) {
      return "History";
    }
    if (location.pathname.includes("reservation")) {
      return "Reservation";
    }
    if (location.pathname.includes("/customer/edit")) {
      return "Edit Record";
    }
    if (location.pathname.includes("/customer/add")) {
      return "Add Record";
    }
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/filtered":
        return "Filtered Clients";
      case "/templates":
        return "Templates";
      case "/send-template":
        return "Templates";
      case "/create-template":
        return "Create New Template";
      case "/special-occasions":
        return "Special Occasions";
      case "/all-clients":
        return "All Clients";
      case "/all-clients-list":
        return "All Clients";
      case "/privacy-policy":
        return "Privacy Policy";
      case "/about-us":
        return "About Us";
      case "/whos-here":
        return "Who's Here";
      case "/choose-server":
        return "Choose Server";
      case "/qr-code":
        return "QR Code";
      case "/export":
        return "Export";
      case "/quick-message":
        return "Quick Message";
      case "/live-table":
        return "Live Table";
      case "/customer-search":
        return "";
      case "/customer-search-result":
        return "";
      case "/customer/add":
        return "Create Account";
      case "/customer/add-later":
        return "Add Later";
      // case "/whos-here-alert":
      //   return "Who's Here Alert";
      default:
        return "Datalekt";
    }
  };

  const onFilterClick = () => {
    if (
      (location?.pathname === "/dashboard" &&
        activeDashboardTab === "reservations") ||
      location?.pathname === "/filtered-reservations" ||
      location?.pathname === "/live-table" ||
      (location?.pathname === "/export" && activeExportTab === "reservation")
    ) {
      dispatch(setOpenReservationFilter(true));
      dispatch(setOpenCheckInFilter(false));
      dispatch(setOpenMainFilter(false));
    } else if (
      (location?.pathname === "/dashboard" &&
        activeDashboardTab === "checkInOut") ||
      location?.pathname === "/filtered-checkin"
    ) {
      dispatch(setOpenReservationFilter(false));
      dispatch(setOpenCheckInFilter(true));
      dispatch(setOpenMainFilter(false));
    } else if (
      (location?.pathname === "/dashboard" &&
        activeDashboardTab === "todaysList") ||
      (location?.pathname === "/export" && activeExportTab === "database") ||
      location?.pathname === "/all-clients-list"
    ) {
      dispatch(setOpenReservationFilter(false));
      dispatch(setOpenCheckInFilter(false));
      dispatch(setOpenMainFilter(true));
    } else {
      dispatch(setOpenReservationFilter(false));
      dispatch(setOpenCheckInFilter(false));
      dispatch(setOpenMainFilter(true));
    }
  };

  const onSettingsClick = () => {
    navigate(`/settings/${Encrypt(templateId)}`);
  };

  return (
    <div className={Style.header}>
      <div className={Style.headerContainer}>
        <IconButton onClick={() => toggleDrawer(true)}>
          <img src={MenuIcon} alt="menu-icon" />
        </IconButton>
        {getPageName() ? (
          <div className={Style.pageNameContainer}>{getPageName()}</div>
        ) : (
          <img className={Style.logoWrapper} src={Logo} alt="Datalekt" />
        )}
        <div className="filterButtonRow">
          {role === USER_TYPE.STAFF && !( (/^\/reservation\/\w+/.test(location.pathname)) ||   location.pathname === "/reservation" ) &&  <Notification />}
          {role === USER_TYPE.MANAGER ||
          (role === USER_TYPE.STAFF &&
            activeDashboardTab === "reservations") ? (
            <>
              {!(
                location.pathname.includes("edit-template") ||
                location.pathname.includes("create-template") ||
                location.pathname.includes("templates") ||
                location.pathname.includes("settings") ||
                location.pathname.includes("customer-search") ||
                location.pathname.includes("whos-here") ||
                location.pathname.includes("live-table") ||
                location.pathname.includes("about-us") ||
                location.pathname.includes("privacy-policy") ||
                (/^\/reservation\/\w+/.test(location.pathname)) ||
                // location.pathname === "/reservation" ||
                // location.pathname.includes("reservation") ||
                location.pathname.includes("customer") ||
                location.pathname.includes("history")
              ) && (
                <IconButton onClick={onFilterClick}>
                  <img src={FilterIcon} alt="filter-icon" />
                </IconButton>
              )}
              {location.pathname.includes("edit-template") && (
                <IconButton onClick={onSettingsClick}>
                  <img src={SettingIcon} alt="filter-icon" />
                </IconButton>
              )}

              {location.pathname.includes("settings") && (
                <Box
                  sx={{
                    height: "52px",
                    width: "52px",
                  }}
                ></Box>
              )}
            </>
          ) : (
            null
            // <Box
            //   sx={{
            //     height: "52px",
            //     width: "52px",
            //   }}
            // ></Box>
          )}
        </div>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleDrawer={toggleDrawer} />
      </div>
      {(openMainFilter || openReservationFilter || openCheckInFilter) && (
        <Filter
          open={openMainFilter || openReservationFilter || openCheckInFilter}
          type={openMainFilter ? 1 : openCheckInFilter ? 2 : 0}
        />
      )}

      <QRModal open={openQRModal} handleClose={handleCloseQRModal} />
      <ChooseServerModal
        open={openChooseServerModal}
        handleClose={handleCloseChooseServerModal}
      />
    </div>
  );
};

export default Header;
