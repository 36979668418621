export const ENDPOINT = {
  STAFF_LOGIN: "/login",
  ADMIN_LOGIN: "/manager/login",
  CHECK_MOBILE_EXIST: "/checkMobileExist",
  REGISTER_USER: "/register1_v2",
  REGISTER_USER_FOR_ADMIN: "/manager/register",
  UPDATE_USER: "/update",
  UPDATE_USER_FOR_ADMIN: "/manager/update",
  GET_AGE: "/getAge",
  GET_AGE_FOR_ADMIN: "/manager/getAge",
  GET_CATEGORY: "/getCategory",
  GET_USER: "/getUserDetailsById_v1",
  GET_USER_FOR_ADMIN: "/manager/getUserDetailsById_v1",
  GET_ALL_USERS: "/getAllUsers1_v1",
  GET_PENDING_USERS: "/getPendingUsers_v1",
  GET_COMPLETED_USERS: "/getCompletedUsers_v1",
  GET_CATEGORIES_FOR_ADMIN: "/manager/getCategory",
  GET_ALL_USERS_FOR_ADMIN: "/manager/getAllUsersPagination_1",
  GET_PENDING_USERS_FOR_ADMIN: "/manager/getPendingUsersPagination",
  GET_COMPLETED_USERS_FOR_ADMIN: "/manager/getCompletedUsersPagination_1",
  GET_ALL_CLIENTS_FOR_ADMIN: "/manager/getAllClient",
  GET_PENDING_CLIENTS_FOR_ADMIN: "/manager/getPendingClient",
  GET_COMPLETED_COMPLETED_FOR_ADMIN: "/manager/getCompletedClient",
  CREATE_TEMPLATE: "/manager/template/create",
  UPDATE_TEMPLATE: "/manager/template/update",
  GET_TEMPLATES: "/manager/template/getTemplates",
  GET_TEMPLATE_BY_ID: "/manager/getTemplateById",
  GET_BIRTHDAYS: "/manager/birthDayList",
  GET_ANNIVERSARIES: "/manager/anniversaryList",
  GET_WHOS_HERE: "/getWhoseHere",
  GET_WHOS_HERE_FOR_ADMIN: "/manager/getWhoseHere",
  GET_WHOS_HERE_SEARCH_FOR_ADMIN: "/manager/getWhoseHereSearch",
  GET_WHOS_HERE_SEARCH: "/getWhoseHereSearch",
  GET_YESTERDAYS_LIST: "/getYesterdaysReservationCount",
  GET_TODAYS_LIST: "/getTodaysReservationCount",
  GET_TOMORROWS_LIST: "/getTommorowsReservationCount",
  GET_YESTERDAYS_LIST_ADMIN: "/getYesterdaysReservationCountAdmin",
  GET_TODAYS_LIST_ADMIN: "/getTodaysReservationCountAdmin",
  GET_TOMORROWS_LIST_ADMIN: "/getTommorowsReservationCountAdmin",
  GET_FILTERED_CLIENTS: "/manager/getUsersWithFilterPagination",
  GET_FILTERED_CHECKINOUT: "/manager/getUsersCheckinCheckoutFilteredData",
  GET_FILTERED_RESERVATION: "/getUsersReservationFilteredData",
  GET_EXPORT_RESERVATION_FILTER_DATA:
    "/manager/getExportReservationFilteredData",
  SEND_MESSAGE: "/weblas_send_message_v3",
  GET_ALL_RESTAURANTS: "/getAllRestaurantLayoutByHotelId",
  GET_ALL_RESTAURANTS_FOR_ADMIN: "/getAllRestaurantLayoutByHotelIdAdmin",
  GET_ALL_RESTAURANTS_LIVE_TABLES: "/getAllReservationByTime",
  REMOVE_TEMPLATE: "/manager/template/remove",
  ADD_RESERVATION: "/addReservation",
  UPDATE_RESERVATION: "/updateReservation",
  UPDATE_RESERVATION_ADMIN: "/updateReservationAdmin",
  EXPORT: "/manager/export",
  EXPORT_RESERVATIONS: "/manager/exportReservationData",
  SEND_QR: "/whatsApp_sent_mail",
  GET_CHECK_IN_LIST: "/getCheckIns",
  GET_CHECK_OUT_LIST: "/getCheckOuts",
  GET_CHECK_IN_LIST_ADMIN: "/getCheckInsAdmin",
  GET_CHECK_OUT_LIST_ADMIN: "/getCheckOutsAdmin",
  GET_MESSAGE_HISTORY: "/getUserMessageHistory",
  GET_RESERVATION_HISTORY: "/getUserReservationHistory",
  GET_CHECKIN_HISTORY: "/getUsercheckinHistory",
  GET_RESERVATION_BY_ID: "/getReservationbyId",
  GET_LIVE_RESERVATION_SEARCH: "/getAllRestaurantLayoutByHotelIdAdminSearch",
  SEARCH_TODAYS_LIST_ADMIN: "/getTodayCountSearchAdmin",
  SEARCH_TODAYS_LIST: "/getTodayCountSearch",
  SEARCH_TOMORROWS_LIST_ADMIN: "/getTommorowCountSearchAdmin",
  SEARCH_TOMORROWS_LIST: "/getTommorowCountSearch",
  SEARCH_YESTERDAYS_LIST_ADMIN: "/getYesterdayCountSearchAdmin",
  SEARCH_YESTERDAYS_LIST: "/getYesterdayCountSearch",
  SEARCH_TOTAL_LIST_ADMIN: "/manager/getAllUsersSearchAdmin",
  SEARCH_TOTAL_LIST: "/getAllUsersSearch",
  SEARCH_PENDING_LIST_ADMIN: "/manager/getPendingUsersSearchAdmin",
  SEARCH_PENDING_LIST: "/getPendingUsersSearch",
  SEARCH_COMPLETED_LIST_ADMIN: "/manager/getCompletedUsersSearchAdmin",
  SEARCH_COMPLETED_LIST: "getCompletedUsersSearch",
  SEARCH_CHECKIN_LIST_ADMIN: "/getCheckInsAdminSearch",
  SEARCH_CHECKOUT_LIST_ADMIN: "/getCheckOutsAdminSearch",
  SEARCH_CHECKIN_LIST: "/getCheckInsSearch",
  SEARCH_CHECKOUT_LIST: "/getCheckOutsSearch",
  DELETE_RESERVATION: "/deleteReservation",
  SEND_WHOSE_HERE_NOTIFICATION: "whosHereNotification",
  CHOOSE_SERVER: "/manager/chooseServer",
  GET_COUNTRIES: "/getCountries",
  GET_ALL_RESERVATION_BY_DATE_TIME: "/getAllReservationByDateTime",
  FILTER_SEARCH: "/manager/getAllFilterUsersSearchAdmin",
  RESERVATION_EXPORT_SEARCH: "/manager/getAllFilterReservationSearchAdmin",
  GET_CLIENTS_COUNT: "/manager/getAllClientCounts",
  CLIENT_SEARCH: "/manager/getUsersClientSectionSearchAdmin",
  IS_ARRIVED: "/reservation/guestArrived",
  TRIGGER_EMAIL: "/manager/send_mail_message_details",
  VERIFY_CAPTCHA: "/captcha/captchaSiteVerify",
  NOTIFICATION_LIST : "/getExternalReservations",
  CANCEL_RESERVATION : "/cancelExternalReservation"
};

export const ERROR_ACTIONS = {
  SESSION_EXPIRED: "SESSION_EXPIRED",
  INTERNAL_ERROR: "INTERNAL ERROR",
  ACCESS_BLOCKED: "Account temporarily locked. Please try again later.",
};

export const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "dark",
};

export const USER_TYPE = {
  MANAGER: "Manager",
  STAFF: "Staff",
};

export const CELL_TYPE = {
  TABLE: "TABLE",
  ENTRANCE: "ENTRANCE",
  PDR: "PDR",
  BAR: "BAR",
};

export const SPENDING_AMOUNTS = [
  {
    amount: 2,
    label: "₹ 2K",
  },
  {
    amount: 4,
    label: "₹ 4K",
  },
  {
    amount: 6,
    label: "₹ 6K",
  },
  {
    amount: 10,
    label: "₹ 10K+",
  },
];

export const genderData = [
  {
    id: 1,
    label: "Male",
  },
  {
    id: 0,
    label: "Female",
  },
];

export const HOURS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const MINUTES = ["00","15", "30", "45"];

export const MERIDIUM = ["AM", "PM"];

export const TRIGGER_POINTS = [
  { value: "Create Booking", name: "Create Booking" },
  { value: "Update Booking", name: "Update Booking" },
  { value: "Cancel Booking", name: "Cancel Booking" },
  { value: "Add New User", name: "Add New User" },
  { value: "Thanks Template", name: "Thanks Template" },
];

export const TEMPLATE_SERVERS = [
  { name: "Server 1", value: "Weblas" },
  { name: "Server 2", value: "wassenger" },
  { name: "Server 3", value: "mtalkz" },
];

export const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes
export const maxVideoSize = 10 * 1024 * 1024; // 10MB in bytes
